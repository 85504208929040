.toggle-promo {
  position: fixed;
  background: $white;
  border-radius: 1rem 1rem 0 0;
  box-shadow: 0 4px 20px 0 rgba(66, 60, 95, 0.20);
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.promo-panel {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  visibility: hidden;
  transform: translateY(100%);
  z-index: 1;
  border-radius: 1rem 1rem 0 0;
  overflow: hidden;
  transition: all 0.3s;
  box-shadow: 0 4px 20px 0 rgba(66, 60, 95, 0.20);

  &.open {
    visibility: visible;
    transform: translateY(0);
  }
}

@include media-breakpoint-up(lg) {
  .promo-panel {
    position: static;
    bottom: auto;
    left: auto;
    visibility: visible;
    transform: translateY(0);
    border-radius: 1rem;
  }
}