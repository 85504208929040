.banner-image {
  .description {
    max-width: 788px;
    width: 100%;
    margin: auto;
  }
}

@include media-breakpoint-up(lg) {
    .banner-image {
        height: 435px;
    }
}