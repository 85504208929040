.ff-headings {
  font-family: $headings-font-family;
}

.heading-decorated {
  &:before {
    display: none;
  }
}

.heading-decorated {
  display: flex;

  &:before {
    display: block;
    content: "";
    background-color: $primary;
    width: 6px;
    border-radius: 3px;
    flex-shrink: 0;
    margin-right: 1rem;
    margin-top: 0;
  }
}

@include media-breakpoint-up(lg) {
  .heading-decorated {
    &:before {
      margin-right: 1.5rem;
    }
  }
}
