.assos-locator {
  height: calc(100vh - 90px);
  display: flex;
  position: absolute;
  width: 100%;
  left: 0;
  top: 90px;

  .map-wrapper {
    flex-grow: 1;
    margin-left: -1rem;
    border-radius: $border-radius;
    position: relative;
    overflow: hidden;

    .map {
      width: 100%;
      height: 100%;
      z-index: 0;
    }

    .assos-details {
      background: $white;
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateY(100%) translateX(-50%);
      z-index: 1;
      width: 100%;
      padding: 1.5rem;
      transition: all 0.3s;
      border-radius: 1rem 1rem 0 0;

      .condor-icon-close {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        font-size: 1rem;
        color: $indigo;
      }

      .logo {
        width: 3.375rem;
        height: 3.375rem;
        box-shadow: 0 4px 20px 0 rgba(134, 135, 126, 0.60);
        border-radius: $border-radius;
        overflow: hidden;
        position: relative;
        margin: -3rem auto 1.25rem auto;
        background-color: $light;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          position: absolute;
          top: 0;
          left: 0;
        }

      }

      &.active {
        display: block;
        transform: translateY(0) translateX(-50%);
      }

    }

  }

}

.price-map {
  background: #FFFFFF;
  border-radius: 8px;
  width: 85px !important;
  height: 42px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 16px;
  font-weight: 700;
  font-family: $headings-font-family;
  color: $primary;
}

@include media-breakpoint-up(md) {
  .assos-locator {
    .map-wrapper {
      .assos-details {
        width: 360px;
        background: transparent;
        padding: 0;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .assos-locator {
    .map-wrapper {
      .assos-details {
        .condor-icon-close {
          position: absolute;
          right: -2rem;
          top: 0;
          z-index: 999;
          font-size: 2rem;
          cursor: pointer ;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .assos-locator {
    top: 107px;
    height: calc(100vh - 107px);

    .map-wrapper {
      .assos-details {
        width: 400px;
      }
    }
  }
}