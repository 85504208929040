@font-face {
    font-family: "condor-icons";
    src: url("./condor-icons.ttf?6da043f39fbf4d7db24918efa0549a3f") format("truetype"),
url("./condor-icons.woff?6da043f39fbf4d7db24918efa0549a3f") format("woff"),
url("./condor-icons.woff2?6da043f39fbf4d7db24918efa0549a3f") format("woff2");
}

i[class^="condor-icon-"]:before, i[class*=" condor-icon-"]:before {
    font-family: condor-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.condor-icon-angle-down:before {
    content: "\e000";
}
.condor-icon-arrow-up:before {
    content: "\e001";
}
.condor-icon-angle-left:before {
    content: "\e002";
}
.condor-icon-angle-right:before {
    content: "\e003";
}
.condor-icon-angle-up:before {
    content: "\f101";
}
.condor-icon-arrow-left-right:before {
    content: "\f102";
}
.condor-icon-arrow-up-down:before {
    content: "\f103";
}
.condor-icon-arrow-left:before {
    content: "\f104";
}
.condor-icon-arrow-right:before {
    content: "\f105";
}
.condor-icon-ascenseur:before {
    content: "\f106";
}
.condor-icon-bagagerie:before {
    content: "\f107";
}
.condor-icon-bar:before {
    content: "\f108";
}
.condor-icon-bed:before {
    content: "\f109";
}
.condor-icon-bike:before {
    content: "\f10a";
}
.condor-icon-boat:before {
    content: "\f10b";
}
.condor-icon-bord-de-mer:before {
    content: "\f10c";
}
.condor-icon-calendar:before {
    content: "\f10d";
}
.condor-icon-campagne:before {
    content: "\f10e";
}
.condor-icon-car:before {
    content: "\f10f";
}
.condor-icon-check:before {
    content: "\f110";
}
.condor-icon-close:before {
    content: "\f111";
}
.condor-icon-coupdecoeur:before {
    content: "\f112";
}
.condor-icon-discotheque:before {
    content: "\f113";
}
.condor-icon-edit:before {
    content: "\f114";
}
.condor-icon-eye-off:before {
    content: "\f115";
}
.condor-icon-eye:before {
    content: "\f116";
}
.condor-icon-facebook:before {
    content: "\f117";
}
.condor-icon-familles:before {
    content: "\f118";
}
.condor-icon-filter:before {
    content: "\f119";
}
.condor-icon-fitness:before {
    content: "\f11a";
}
.condor-icon-Gift:before {
    content: "\f11b";
}
.condor-icon-info:before {
    content: "\f11c";
}
.condor-icon-instagram:before {
    content: "\f11d";
}
.condor-icon-mail:before {
    content: "\f11e";
}
.condor-icon-map-1:before {
    content: "\f11f";
}
.condor-icon-map:before {
    content: "\f120";
}
.condor-icon-menu:before {
    content: "\f121";
}
.condor-icon-minus:before {
    content: "\f122";
}
.condor-icon-motorbike:before {
    content: "\f123";
}
.condor-icon-parking:before {
    content: "\f124";
}
.condor-icon-person:before {
    content: "\f125";
}
.condor-icon-phone:before {
    content: "\f126";
}
.condor-icon-piscine-exterieure:before {
    content: "\f127";
}
.condor-icon-piscine-interieure:before {
    content: "\f128";
}
.condor-icon-plus:before {
    content: "\f129";
}
.condor-icon-rando:before {
    content: "\f12a";
}
.condor-icon-reception:before {
    content: "\f12b";
}
.condor-icon-restaurant:before {
    content: "\f12c";
}
.condor-icon-search:before {
    content: "\f12d";
}
.condor-icon-spa:before {
    content: "\f12e";
}
.condor-icon-star:before {
    content: "\f12f";
}
.condor-icon-tennis:before {
    content: "\f130";
}
.condor-icon-terrasse:before {
    content: "\f131";
}
.condor-icon-timer:before {
    content: "\f132";
}
.condor-icon-truck:before {
    content: "\f133";
}
.condor-icon-ville:before {
    content: "\f134";
}
.condor-icon-wheelchair:before {
    content: "\f135";
}
.condor-icon-wifi:before {
    content: "\f136";
}
.condor-icon-x:before {
    content: "\f137";
}
