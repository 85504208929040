.counter {
  display: flex;
  align-items: center;
  button {
    width: 2rem;
    height: 2rem;
    background: $white;
    border: 1px solid #D0D5DD;
    color: $dark;
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-child {
      border-right: none;
      border-radius: 6px 0 0 6px;
    }

    &:last-child {
      border-left: none;
      border-radius: 0 6px 6px 0;
    }
  }

  input {
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 32px;
    border: 1px solid #D0D5DD;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    -moz-appearance: textfield;
    text-align: center;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

  }
}