.search-widget {

  .widget_item {
    border-bottom: 1px solid #D0D5DD;
    height: 100%;

    &.alert-empty {
      span {
        position: relative;
        &:after {
          content: "!";
          width: 12px;
          height: 12px;
          background-color: $primary;
          border-radius: 9999px;
          color: $white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.625rem;
          position: absolute;
          right: -1rem;
          top: calc(50% - 6px);
        }
      }

      p {
        color: $primary !important;
      }
    }

  }

  .overlay-widget {
    position: fixed;
    top: 0;
    left: 0;
    background: #000;

    width: 100%;
    height: 100%;
    z-index: 3;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
  }

  .search-widget-panel {
    background: $white;
    border-radius: 1rem 1rem 0 0;
    box-shadow: 0 4px 20px 0 rgba(66, 60, 95, 0.20);

    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 90%;
    z-index: 999;

    transform: translateY(100%);
    visibility: hidden;
    transition: all 0.5s;

    .close-panel-period {
      position: absolute;
      z-index: 1000;
      top: 1rem;
      right: 1rem;
      font-size: 2rem;
    }

    &.opened {
      transform: translateY(0);
      visibility: visible;
      overflow: hidden;
    }
  }

  .custom-checkbox-panel {
    label {
      background: $white;
      transition: all 0.3s;

      &:hover {
        background: $gray-100;
      }
    }
  }

  .custom-checkbox-panel input[type="radio"] {
    &:checked + label {
      background: $gray-100;
    }
  }

  &.search-widget-vertical {
    position: static !important;

    .vertical {
      display: flex;
      flex-direction: column;
      margin-bottom: 1.5rem !important;

      .widget_item {
        width: 100%;
        border: 1px solid #D0D5DD !important;
        border-top: 0 !important;

        &:first-child {
          border-top: 1px solid #D0D5DD !important;
          border-radius: 8px 8px 0 0;
        }

        &.widget-car {
          border-radius: 0 0 8px 8px;
        }

        &.widget-car {
          grid-column: auto/span 6;
        }

        &.widget-place {
          padding-left: 1rem !important;
        }
      }

      .widget_btn {
        display: none;
      }

    }

    .search-widget-panel {
      bottom: 0;
      left: 0;
      height: 100% !important;
      box-shadow: none !important;


      .condor-icon-close {
        display: inline !important;
      }
    }
  }

  &.active {
    .overlay-widget {
      opacity: 0.25;
      visibility: visible;
    }
  }
}

@include media-breakpoint-up(lg) {
  .search-widget {
    position: relative;

    .widget_item {
      border-bottom: none;
      border-right: 1px solid #D0D5DD;

      &.widget-car {
        border-right: none;
      }
    }

    .search-widget-panel {
      position: absolute;
      bottom: auto;
      left: auto;
      height: auto;
      margin-top: 1.5rem;
      overflow: hidden;
      opacity: 0;
      border-radius: 1rem;
      box-shadow: none !important;
      z-index: 1;

      transform: initial;

      .select-list-container {
        height: 230px;
        overflow-y: auto;
      }

      &.opened {
        opacity: 1;
      }
    }

    .btn-search {
      width: 52px;
      height: 52px;
    }

    &.search-widget-vertical {

      .search-widget-panel {
        padding: 0 !important;
        top: 0;

        .search-widget-guests-header {
          padding: 1rem !important;
        }

        .search-widget-place-header {
          padding: 1rem 1.5rem 0 1.5rem !important;
        }

        .v-search-widget-car {
          padding: 1rem 1.5rem 1rem 1.5rem !important;

          .search-widget-car-header {
            display: flex !important;
            justify-content: space-between !important;
            margin-bottom: 1.5rem !important;
          }

          .v-select-cars {
            flex-direction: column !important;

            > div {
              justify-content: space-between !important;
            }

            ul {
              text-align: left !important;
            }
          }
        }

        .v-switch-car {
          padding: 1.5rem !important;

          > div {
            justify-content: space-between !important;
          }
        }

        .v-select-rooms {
          padding: 1rem !important;

          .select-list-container {
            height: 355px;
            overflow-y: auto;
          }

          ul {
            display: block !important;

            li {
              width: 100% !important;
              justify-content: space-between !important;

              &:first-child {
                margin-bottom: 1rem !important;
              }
            }
          }
        }

        .v-select-age {
          display: block;

          select {
            margin-bottom: 1rem;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        .v-number-rooms {
          gap: 0 !important;
          justify-content: space-between !important;
        }

        .v-valid-guest, .v-valid-car {
          display: flex !important;
        }

        .datepicker__topbar {
          margin-bottom: 0.5rem;
        }

        .datepicker__months {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }

        .datepicker__month-day {
          position: relative;
          height: 2.5rem;
          font-weight: 700;
          font-size: 0.875rem;
          color: rgb(22, 22, 67);
          padding: 0 16px;
        }

        &.opened {
          transform: translateY(-24px) !important;
        }
      }
    }
  }
}