@import "abstracts/variables";
@import "abstracts/mixins";
@import "abstracts/api";


// Vendors
@import "~bootstrap";
@import "~@splidejs/splide/dist/css/splide-core.min.css";
@import "~hotel-datepicker";
@import "~photoswipe/dist/photoswipe.css";

// Fonts
@import "../fonts/icons/condor-icons.css";
@import "../fonts/avant-garde/stylesheet.css";
@import "../fonts/mulish/stylesheet.css";

// Abstracts

// Base
@import "base/buttons";
@import "base/colors";
@import "base/icons";
@import "base/typography";
@import "base/container";
@import "base/dropdown";

// Layout
@import "layout/layout";

// Components
@import "components/banner";
@import "components/banner-image";
@import "components/footer";
@import "components/search-header";
@import "components/search-map";
@import "components/site-header";
@import "components/cards-offres";
@import "components/filter";
@import "components/splide";
@import "components/services-picto";
@import "components/card-city";
@import "components/sticky";
@import "components/card-service";
@import "components/search-widget";
@import "components/hdpkr";
@import "components/counter";
@import "components/step";
@import "components/switch";
@import "components/card-reservation";
@import "components/card-gift";
@import "components/sticky-recap-mobile";
@import "components/toggle-promo";
@import "components/separator";
@import "components/access-map";

// Pages
@import "pages/home";
@import "pages/detail-offre";

@import "adds/_buttonsPlusMinus.scss";
@import "adds/_validationMessage.scss";
@import "adds/_form.scss";

// Tunnel