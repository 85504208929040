.tunnel-title {
  margin-bottom: 2rem;

  .wizard_container {
    max-width: 681px;
    width: 100%;
    margin: auto;

    .wizard {
      display: flex;
      justify-content: space-between;
      padding: 0;
      width: 100%;
      margin: auto;

      .step {
        position: relative;
        list-style: none;
        display: flex;
        flex-direction: column;
        width: 25%;

        .node {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 42px;
          height: 42px;
          margin: 0 auto;
          border-radius: 9999px;
          background: #fff;
          color: $deep-blue;
          font-size: 1.5rem;
          font-weight: 700;
          border: 2px solid $deep-blue;
          margin-bottom: 0.5rem;
          z-index: 1;
        }

        .step_label {
          display: none;
          text-align: center;
          font-weight: 600;
        }

        &:not(:last-child):after {
          background-color: $deep-blue;
          content: "";
          display: block;
          height: 2px;
          left: 50%;
          top: 40%;
          position: absolute;
          width: 100%;
          z-index: 0;
        }

        &.active {
          .node {
            background: $primary;
            color: $white;
          }
        }

        &.unactive {
          .node {
            background: $deep-blue;
            color: $white;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .tunnel-title {
    .wizard_container {
      .wizard {
        .step {
          .step_label {
            display: block;
          }

          &:not(:last-child):after {
            top: 20%;
          }
        }
      }
    }
  }
}