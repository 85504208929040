footer {
  background-color: $deep-blue;
  color: $light;
  padding: 73px 0 73px 37px;

  .nav-footer {
    .logo {
      margin-bottom: 37px;
      display: block;
    }

    ul {
      padding: 0;
      margin-bottom: 45px;

      li {
        list-style: none;

        a {
          color: $gray-100;
          text-decoration: none;
        }
      }

      &:nth-last-child(1) {
        margin-bottom: 35px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  footer {
    .nav-footer {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      margin-bottom: 90px;
    }
  }
}