@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin btn-reset() {
  padding: 0;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background: none
}