﻿.input-validation-error {
    border-color: var(--bs-form-invalid-border-color);
}

.input-validation-error:not(.form-check-input) {
    background-position: right calc(.375em + .375rem) center;
    background-repeat: no-repeat;
    background-size: calc(.75em + .75rem) calc(.75em + .75rem);
    padding-right: calc(1.5em + 1.5rem);
}
 


.field-validation-error {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545;
}

.input-validation-error ~ .field-validation-error {
    display: block;
}