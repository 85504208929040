.bg-deep-blue {
  background-color: $deep-blue !important;
}

.bg-indigo {
  background-color: $indigo !important;
}

.text-deep-blue {
  color: $deep-blue !important;
}

.text-indigo {
  color: $indigo !important;
}

.text-primary {
    color: $primary !important;
}

.text-gray {
  color: #525252;
}

.text-light-gray {
  color : #7B7B7B;
}

.text-stars {
  color: $yellow;
}

.bg-overlay {
  background: rgba(30, 26, 19, .45);
}

.bg-form {
  background: #F3F4F9B2;
}

.bg-very-light-primary {
  background: rgba(204, 51, 153, 0.08);
}

.bg-hotel-available {
  background: rgba(0, 153, 153, 0.90);
}

.bg-gray-100 {
  background: $gray-100;
}

.border-deep-blue {
  border-color: $deep-blue !important;
}

.border-indigo {
  border-color: $indigo !important;
}