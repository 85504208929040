@include media-breakpoint-up(lg) {

  body.page-home {

    site-header {
      border-bottom: 0;
      background-color: rgba($white, .7);
      backdrop-filter: blur(4px);
    }
  }

}
