.btn-reset {
  padding: 0;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background: none;
}

.btn-outline-primary {
  --bs-btn-bg: #{rgba($primary, .05)};
}

.btn-form {
  max-width: 340px;
  width: 100%;
  margin: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}

.btn-detail {
  @include btn-reset();
  text-decoration: none;
  padding-bottom: 2px;
  border-bottom: 2px solid $primary;
  color: $primary;

  &:disabled {
    border-bottom: 2px solid $gray-300;
    color: $gray-300;
  }
}

.btn-card-img {
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  color: $white;
  background: $primary;
  border: none;
}

.btn-secondary {
  @include button-variant(
                  $white, // Background
                  $white, // Border
                  $body-color, // Color
                  $gray-100, // Hover background
                  $gray-100, // Hover border
                  $body-color, // Hover color
  );

  .btn-success {
    @include button-variant(
                    $green, // Background
                    $white,
                    $body-color,
                    $green,
                    $white,
                    $body-color
    )
  }

  box-shadow: $box-shadow-sm;

  &:disabled,
  &.disabled {
    color: $gray-500;
  }
}

.btn-cadeau {
  height: 44px;
  border-radius: 10px;
}

.custom-checkbox input[type="radio"],
.custom-checkbox input[type="checkbox"] {
  &:checked + label {
    background-color: $primary;
    color: #fff;

    > .click-me {
      display: none;
    }

    > .condor-icon {
      display: block;
    }
  }
}

.custom-checkbox label {
  border: 2px solid $primary;
  font-size: 1.125rem;
  cursor: pointer;
  color: $primary;

  transition: all 0.3s;
  width: 215px;

  > .condor-icon {
    display: none;
  }
}

@include media-breakpoint-up(md) {
  .custom-checkbox label {
    width: 215px;
  }
}