

.sticky-cta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding: 0.8125rem 0.9375rem;
  position: sticky;
  z-index: 1;
  bottom: -1px;
  margin: 0 -10px;
  background-color: $white;
  border-radius: 16px 16px 0 0;
  box-shadow: $box-shadow;
  max-width: initial;
  width: var(--viewportWidth);

  &:not(.is-sticky) {
    opacity: 0;
    height: 0;
    overflow: hidden;
  }

}

@include media-breakpoint-up(xl) {
  .sticky-cta {
    display: none;
  }
}