site-header {
    background-color: $white;
    z-index: 3;
    border-bottom: 1px solid $border-color;

    .bot-header {
        height: $header-height;
    }

    [class^="container"] {
        display: flex;
        align-items: center;
        gap: 1.5rem;
    }

    .BF-logo {
        width: 225px;
        margin-right: 25px;
    }

    a.logo {
        svg {
            width: 110px;
        }
    }

    .toggle-nav {
        display: none;
    }

    nav {
        margin-left: auto;

        ul {
            @include reset-list();
            display: flex;
            align-items: center;
            gap: 1rem;
        }
    }

    .toggle-search {
        @include btn-reset();
        width: 250px;
        height: 45px;
        box-shadow: $box-shadow;

        .icon-loupe {
            width: 40px;
            height: 40px;

            i {
                font-size: 1.5rem;
            }
        }
    }
}

.link-espace-pro {
  color: $yellow;
  font-size: 0.875rem;
  padding: 2px 8px;
  background: rgba(243, 244, 249, 0.15);
  border-radius: 10px;
}

.header-tunnel {
  .logo {
    width: 135px;
  }

  .price-total-header {
    font-size: 1.75rem;
  }
}

@include media-breakpoint-down(xl) {

    site-header {

        .bot-header {
            height: $header-height-mobile;
        }

        a.logo {
            svg {
                width: 110px;
            }
        }

        .toggle-nav {
            @include btn-reset();
            margin-left: auto;
            display: block;
            font-size: 1.5rem;

            i[class$="close"] {
                display: none;
            }

            &.active {

                i[class$="menu"] {
                    display: none;
                }

                i[class$="close"] {
                    display: block;
                }
            }
        }

        nav {
            display: none;

            &.opened {
                display: block;
                position: fixed;
                top: $header-height-mobile;
                left: 0;
                right: 0;
                background-color: $white;
                z-index: $zindex-fixed
            }

            ul {
                border-radius: 0 0 .5rem .5rem;

                li {
                    a {
                        font-size: 1.125rem;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
  site-header {
    nav {
      ul {
        gap: 0 2rem;
      }
    }

    .toggle-search {
      margin-left: 3rem;
    }
  }

  .espace-pro {
    z-index: 10;
  }

  .header-tunnel {
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    .logo {
      width: 175px;
    }

    .price-total-header {
      font-size: 2.375rem;
    }
  }
}