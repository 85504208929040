// CARTES OFFRES
.card-offre {
  transition: ease-in-out 0.3s;

  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}


.card-offre-visuel {
  height: 260px;

  img {
    transition: ease-in-out 0.3s;
  }
}

.card-options-visuel {
  height: 200px;
}

.card-offre-info {
  width: 125px;
  padding: 10px;
  font-size: 0.625rem;
  font-weight: 600;
  color: $deep-blue;
  background: $white;
  line-height: 12px;
  top: 1.5rem;
  right: 1.5rem;
  border-radius: 10px;
  z-index: 1;

  span {
    font-size: 1.625rem;
    line-height: normal;
    font-family: "ITC Avant Garde Std Md";
  }
}

.card-offre-alert {
  font-size: 0.875rem;
}

.card-offre-content {
  .card-offre-picto {
    background: $gray-100;
    border: 1px solid #D0D5DD;
    width: 38px;
    height: 38px;
    z-index: 2;

    i {
      font-size: 2rem;
    }
  }


  .card-offre-bottom {
    height: 58px;

    .card-offre-bottom-promo {
      background: rgba(204, 51, 153, 0.05);
      border-radius: 10px;
      width: 158px;
    }

    a {
      top: calc(50% - 16px);
      padding-bottom: 2px;
      border-bottom: 2px solid $primary;
      font-size: 1.125rem;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }
}

.card-offer-map-image {
  height: 150px;
}

@include media-breakpoint-up(lg) {

  .card-options-visuel {
    width: 210px;
    height: auto;
  }
}