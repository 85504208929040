.access-map {
    position: fixed;
    bottom: 24px;
    background: rgba(18, 22, 41, 0.86);
    color: $gray-100;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 220px;
    width: 100%;
    height: 42px;
    font-size: 1rem;
    left: calc(50% - 110px);
    gap: 0 8px;
    text-decoration: none;
    cursor: pointer;
    z-index: 3;
}

@include media-breakpoint-up(lg) {
  .access-map {
    bottom: 72px;
  }
}