.splide {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  gap: 1.25rem;

  .splide__arrows {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
  }

  .splide__arrow {
    position: relative;
    border: 0;
    background-color: transparent;
    width: 1.5rem;
    height: 1.5rem;

    svg {
      display: none;
    }

    &:before {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      content: "\e002";
      font-family: condor-icons, sans-serif;
      font-size: 1.25rem;
      line-height: 1;
      transform: translateX(-50%) translateY(-50%);
      color: white;
      mix-blend-mode: difference;
    }

    &.splide__arrow--next {

      &:before {
        content: "\e003";
      }

    }

  }

  .splide__count {
    background-color: $indigo;
    color: $white;
    border-radius: $border-radius;
    padding: .125rem .5rem;
  }

  .splide__pagination {
    display: none;
  }

}