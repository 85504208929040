.container_form {
  max-width: 792px;
  width: 100%;
  margin: auto;
}

.container-espace-pro {
  max-width: 350px;
  width: 100%;
  margin: auto;
}
