.filter {
  select {
    max-width: 250px;
    padding: 0.875rem;
  }
}

.btn-filter {
  .pastille-icon {
    position: absolute;
    width: 1rem;
    height: 1rem;
    background: $primary;
    top: -5px;
    right: -7px;
    border-radius: 9999px;
  }
}