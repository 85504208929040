.sticky-recap-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8125rem 0.9375rem;
  position: fixed;
  z-index: 0;
  bottom: 0;
  left: 0;
  background-color: $white;
  border-radius: 16px 16px 0 0;
  box-shadow: $box-shadow;
  max-width: initial;
  width: 100%;
}

@include media-breakpoint-up(xl) {
  .sticky-recap-mobile {
    display: none;
  }
}