.detail-sejour_gallery_list {
  img {
    filter: grayscale(100%);
  }
}

.price-footer {
  .price-footer_value {
    font-size: 2.25rem;
  }
}