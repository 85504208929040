.reservation-card {
  box-shadow: $box-shadow;
  position: fixed;
  bottom: 0;
  left: 0;
  transition: all 0.3s;
  transform: translateY(100%);
  z-index: 3;
  height: 90%;
  width: 100%;

  &.open {
    transform: translateY(0);
  }
}

@include media-breakpoint-up(xl) {
  .reservation-card {
    top: 100px;
    bottom: auto;
    left: auto;
    overflow-y: initial;
    height: auto;
    transform: translateY(0);
    position: relative;
    z-index: 1;
    transition: all 0.3s;

    &.reservation-vertical-open {
      height: 740px;
      position: static;
    }
  }
}