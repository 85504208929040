/**
 * Bootstrap Variables
 */

// Colors

$gray-100: #F3F4F9;
$gray-900: #121629;

$indigo: #423C5F;
$deep-blue: rgb(22, 22, 67);

$pink: #CC3399;
$red: #F65555;
$green: #009999F2;
$orange: #FEA24B;
$yellow: #E5B73B;

$light: $gray-100;
$dark: $gray-900;
$primary: $pink;
$success: $green;
$danger: $red;
$warning: $orange;

$white: #FFFFFF;

// Typography

$font-family-base: "Mulish", sans-serif;
$font-size-base: 1rem;

$headings-font-family: "ITC Avant Garde Std Md", sans-serif;
$headings-font-weight: 700;
$headings-margin-bottom: .7em;
$headings-color: $deep-blue;

$h1-font-size: 2.875rem; // 46px
$h2-font-size: 2rem; // 32px
$h3-font-size: 1.75rem; // 28px
$h4-font-size: 1.75rem; // 24px
$h5-font-size: 1.375rem; // 22px

$font-sizes: (
        1: 3rem,
        2: 2rem,
        3: 1.75rem,
        4: 1.5rem,
        5: 1.25rem,
        6: 1.125rem,
        7: 1rem,
        8: .875rem,
        9: 0.75rem
) !default;


// Options

$enable-cssgrid: true;
$enable-negative-margins: true;
$enable-rfs: false;

// Body

$body-bg: #FAF6F3;
$body-bg-tunnel: #F6F0EB;
$body-color: $gray-900;

// Components

$box-shadow: 0 4px 20px 0 rgba($deep-blue, .2);
$box-shadow-sm: 0 1px 4px 0 rgba($deep-blue, .2);

// Spacing

$spacer: 1rem;

// Modals
$modal-content-bg: $white;
$modal-lg: 780px;

// Forms
$form-check-input-color: $white;

// Container padding

$container-padding-x: 1.25rem;

// Dropdown

$dropdown-bg: $white;
$dropdown-min-width: 21rem;
$dropdown-padding-x: 1.5rem;
$dropdown-padding-y: 1.5rem;
$dropdown-border-color: transparent;
$dropdown-box-shadow: 0px 4px 20px 0px rgba(66, 60, 95, 0.20);
$dropdown-border-radius: 1rem;

$spacers: (
        0: 0,
        1: $spacer * .25, // 4px
        2: $spacer * .5, // 8px
        3: $spacer * .75, // 12px
        4: $spacer * 1, // 16px
        5: $spacer * 1.25, // 20px
        6: $spacer * 1.5, // 24px
        7: $spacer * 2, // 32px
        8: $spacer * 2.5, // 40px
        9: $spacer * 3, // 48px
        10: $spacer * 4, // 64px
        11: $spacer * 5, // 80px
        12: $spacer * 6, // 96px
        13: $spacer * 8, // 144px
        14: $spacer * 10, // 160px
        15: $spacer * 12, // 192px
        16: $spacer * 14, // 224px
);

// Buttons + Forms

$container-max-widths: (
        sm: 680px,
        md: 768px,
        lg: 1024px,
        xl: 1200px,
        xxl: 1320px
) !default;

$grid-breakpoints: (
        xs: 0,
        sm: 640px,
        md: 768px,
        lg: 1024px,
        xl: 1200px,
        xxl: 1400px
) !default;


// Buttons

$btn-padding-y: .625rem;
$btn-padding-x: 1.375rem;
$btn-font-weight: 400;
$btn-border-width: 2px;
$btn-close-bg: url('data:image/svg+xml, <svg viewBox="0 -6 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.0009 14.1153L22.6005 7.51562L24.4861 9.40124L17.8865 16.0009L24.4861 22.6005L22.6005 24.4861L16.0009 17.8865L9.40124 24.4861L7.51562 22.6005L14.1153 16.0009L7.51562 9.40124L9.40124 7.51562L16.0009 14.1153Z" fill="currentColor"/></svg> ');
$btn-close-width: 2rem;
$btn-close-height: 2rem;
$btn-close-color: $indigo;
$btn-close-opacity :1;


  // Forms
$form-label-font-size: 0.875rem;

$input-font-size: .875rem;
$input-btn-padding-y: .75rem;
$input-btn-padding-x: .875rem;
$input-bg: $white;
$input-border-radius: 8px;
$input-btn-line-height: 1.5;
$input-border-color: #DBDCDF;
$input-color: #50565E;

$form-select-padding-y: 1.125rem !default;
$form-select-padding-x: .875rem !default;
$form-select-line-height: 1.5;
$form-select-font-size: 0.875rem;
$form-select-color: $dark;

$form-feedback-icon-valid: "";
$form-feedback-icon-invalid: "";

  /**
   * Project variables
   */

$header-height: 75px;
$header-height-mobile: 90px;

$breadcrumb-divider: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 -6 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M17.5618 16.0004L10.9622 9.40065L12.8478 7.51504L21.3332 16.0004L12.8478 24.4857L10.9622 22.6L17.5618 16.0004Z" fill="#423C5F"/></svg>');