.card-services {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 412px;
  border-radius: 8px;
  overflow: hidden;
  padding: 0.5rem;

  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    transition: all 0.3s;
  }

  &:hover {
    >img {
      transform : scale(1.1);
    }
  }
}