.card-gift {
  transition: all 0.3s;
  position: relative;

  .card-gift-visuel {
    height: 200px;
  }

  .card-gift-price {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: $white;
    border-radius: 10px;
    z-index: 1;
  }

  a {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}