body {
  --viewportWidth: calc(100vw - var(--scrollbarWidth));
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex-grow: 1;
}

img {
  max-width: 100%;
  height: auto;

  &.gray-picture {
    filter: grayscale(100%);
  }
}

.cursor-pointer {
  cursor: pointer;
}

.price-value-recap {
  width: 115px;
}

.price-value-detail {
    width: 85px;
}

.bg-body-tunnel {
  background: $body-bg-tunnel;
}


.step-success {
  .step-success_visuel {
    max-width: 330px;
  }

  .step-success_visuel-error {
    max-width: 106px;
    width: 100%;
    margin: auto;
  }

  a {
    width: 210px;
    margin: auto;
  }
}

.input-password {
  i {
    font-size: 1.5rem;
    position: absolute;
    right: 1rem;
    top: 12px;
  }
}