.datepicker {
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0 0 0;
  font-family: $font-family-base;
  color: $body-color;
  margin: 0 auto;
}

.datepicker__inner {
  padding: 0;
}

.datepicker__month {
  width: 340px;
}

.datepicker__month-caption {
  border: 0;
  height: 2rem;
}

.datepicker__month-name {
  text-transform: none;
  font-size: 1rem;
}

.datepicker__week-days {
  height: 2rem;
}

.datepicker__week-name {
  font-size: .875rem;
  font-weight: 700;
  text-transform: none;
}

.datepicker__month-day {
  position: relative;
  height: 3rem;
  font-weight: 700;
  font-size: 0.875rem;
  color: $deep-blue;

  &:focus {
    outline: none;
    background-color: inherit;
  }

  &--today {
    background-color: inherit;
    color: inherit;
  }

  &--first-day-selected,
  &--last-day-selected {
    background-color: inherit;
    color: $white;

    &:before {
      display: block;
      content: "";
      border-radius: 8px;
      width: 50px;
      height: 50px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      background-color: $primary;
      z-index: -1;
    }

    &:after {
      display: block;
      content: "";
      width: 50%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%);
      background-color: rgba($primary, 20%);
      z-index: -1;
    }
  }

  &--last-day-selected {

    &:after {
      left: 0;
    }

  }

  &--hovering,
  &--selected:not(.datepicker__month-day--first-day-selected):not(.datepicker__month-day--last-day-selected) {
    background-color: inherit;
    color: inherit;

    &:after {
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      background-color: rgba($primary, 20%);
      z-index: -1;
    }

  }

  &--no-checkin {

    &:before {
      border-width: 0;
    }

  }

  &--no-checkin:not(.datepicker__month-day--selected) {
    background-color: inherit;
    color: inherit;

    &:before {
      display: block;
      content: "";
      border-radius: 0 100px 100px 0;
      width: 25px;
      height: 50px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%);
      background-color: rgba($dark, .1);
      z-index: -1;
    }

  }

  &--invalid {
    color: rgba($dark, .25);
  }

  &--disabled {
    color: rgba($dark, .5);
    text-decoration: line-through;

    &:after {
      content: "";
    }

  }

}

.datepicker__topbar {
  margin-bottom: 1.5rem;
  padding: 1.25rem 3rem;
  background-color: $gray-100;
  border-radius: $border-radius;
  line-height: 1.5;
  width: 100%;
}

.datepicker__info-text--selected-days {
  display: block;
}

.datepicker__info-text,
.datepicker__info--selected,
.datepicker__info--help {
  font-size: 1rem;
  text-transform: none;
  text-align: center;
}

.datepicker__info--separator {
  padding: 0 0.125rem;
}

.datepicker__month-button--prev, .datepicker__month-button--next {
  border-radius: 8px;
  border: 1px solid $gray-300;
  width: 36px;
  height: 36px;
  position: relative;
}
.datepicker__month-button--next:after {
  content: "\e003";
  font-family: condor-icons, sans-serif;
  background-image: none;
  font-size: 1.5rem;
  position: absolute;
  top: calc(100% - 1.5rem);
  left: calc(85% - 1.5rem);
}

.datepicker__month-button--prev:after {
  content: "\e002";
  font-family: condor-icons, sans-serif;
  background-image: none;
  font-size: 1.5rem;
  position: absolute;
  top: calc(100% - 1.5rem);
  left: calc(85% - 1.5rem);
}


@include media-breakpoint-down(lg) {

  .datepicker {
    width: auto;
  }

  .datepicker__month {
    width: 320px;
  }

}

@include media-breakpoint-down(md) {

  .datepicker__months {
    display: flex;
    justify-content: center;

    &:before {
      display: none;
    }

  }

  .datepicker__month {
    margin: 0 auto;
  }

  .datepicker__month--month2 {
    display: none;
  }

  .datepicker__info-text,
  .datepicker__info--selected,
  .datepicker__info--help {
    font-size: 0.875rem;
  }

}

@include media-breakpoint-up(md) {
  .datepicker__months {
    max-width: 732px;
    width: auto;
    margin: auto;
    &:before {
      content: none;
    }
  }
}