.search-header {

  .search-header-widget-mobile {
    background: $white;
    visibility: hidden;
    transform: translateY(100%);
    transition: all 0.3s;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    padding: 0.5rem 0;

    &.open {
      visibility: visible;
      transform: translateY(0);
    }
  }

  .search-header-widget-setting {
    width: 50px;
    height: 50px;
    position: fixed;
    top: 7rem;
    right: 1rem;
    background: $primary;
    border-radius: 999px;
    z-index: 2;
    font-size: 1.5rem;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .overlay-widget {
    display: none;
  }
}

@include media-breakpoint-up(lg) {

    .search-header {
        position: sticky;
        z-index: $zindex-sticky;
        top: $header-height;
        background-color: $white;
        border-bottom: 1px solid $border-color;
        transition: all ease .3s;


        .search-header-widget-mobile {
            visibility: visible;
            transform: translateY(0);
            position: static;
            width: 100%;
            height: auto;
            bottom: auto;
            left: auto;
        }

        &:not(.opened, .force-opened) {
            max-height: 0;
            border-width: 0;
            box-shadow: 0 0 0 transparent;
            overflow: hidden;
        }

        .overlay-search-header {
            position: absolute;
            width: 100%;
            height: 100vh;
            background-color: rgba(0, 0, 0, 0.25);
            top: 0;
            left: 0;
        }

        &.force-opened {
            .overlay-search-header {
                display: none;
            }
        }

        &.opened, &.force-opened {
            top: 109px;
        }
    }
}