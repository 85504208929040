.banner {
    margin-top: -$header-height;
    padding: calc($header-height-mobile + 3rem) 0 5rem 0;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;

    h1 {
        text-align: center;
        @include font-size(3rem);

        small {
            display: block;
            color: $primary;
            @include font-size(3rem);
        }
    }

    &::after {
        content: "";
        display: block;
        width: 100%;
        height: 730px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        background-image: url("../../img/visuels/condor-img-home.jpg");
        background-size: cover;
    }
}

.banner-pink {
  position: relative;

  &:after {
    content: "";
    width: var(--viewportWidth);
    height: 550px;
    display: block;
    background: #CC339908;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: -10;
    transform: translate(-50%);
    background-size: cover;
  }
}

@include media-breakpoint-up(lg) {
    .banner {
        overflow: visible;
        height: 770px;
        padding: 0;

        h1 {
            @include font-size(4.25rem);
        }
    }

    .banner-pink {
        &:before {
            content: "";
            width: var(--viewportWidth);
            height: 340px;
            display: block;
            position: absolute;
            top: 25%;
            left: 50%;
            z-index: -10;
            transform: translate(-50%);
            background-size: cover;
            background-image: url("../../img/visuels/bg-destination.svg");
            opacity: 0.25;
        }
    }

    .banner-blue {
        position: relative;

        &:after {
            content: "";
            width: var(--viewportWidth);
            height: 365px;
            display: block;
            background: $deep-blue;
            position: absolute;
            bottom: 0;
            left: 50%;
            z-index: -10;
            transform: translate(-50%, 48px);
            background-size: cover;
            background-image: url("../../img/visuels/bg-blue-banner.svg");
        }
    }
}