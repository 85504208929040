.card-region {
  position: relative;
  height: 560px;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.25rem;
  color: $white;
  background-color: $dark;
  overflow: hidden;
  text-decoration: none;

  &:before {
    display: block;
    content: "";
    background-image: linear-gradient(transparent, black);
    opacity: .45;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: $border-radius;
    z-index: 1;
  }

  > * {
    position: relative;
    z-index: 2;
  }

  h3 {
    color: $white;
    text-align: center;
    text-transform: uppercase;
    line-height: 1.4;
    span {
      display: block;
      font-size: 2.5rem;
    }
  }

  > img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: $border-radius;
    object-fit: cover;
    object-position: center;
    z-index: 0;
    transition: all ease .3s;
  }

  .card-city-link {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
  }

  &:hover {

    > img {
      transform: scale(1.1);
    }

  }

}