/* The switch - the box around the slider */
.switch {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 36px;
  height: 20px;

  input {
    display: none;

    &:checked + .slider {
      background-color: $primary;
    }

    &:checked + .slider:before {
      transform: translateX(16px);
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $primary;
    }
  }
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;

  &:before {
    position: absolute;
    content: "";
    height: 1rem;
    width: 1rem;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 9999px;
  }

  &.round {
    border-radius: 9999px;

    &:before {
      border-radius: 50%;
    }
  }
}